import React, { useState, useEffect, useContext } from 'react';
import Modal from '@mui/material/Modal';
import '../styles/RestaurantInfoWindow.css';
import { IN_DEV, localhost_server, path_server } from './api.js';
import { UserContext } from './UserContext';

const RestaurantInfoWindow = ({ restaurant, onClose, onDelete }) => {
  const [comments, setComments] = useState([]);
  const [images, setImages] = useState([]);
  const [activeTab, setActiveTab] = useState('comments');
  const [locationDetails, setLocationDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);
  const { loggedInUser } = useContext(UserContext);

  useEffect(() => {
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/comments_and_images/${encodeURIComponent(restaurant._id.$oid)}`)
      .then(response => response.json())
      .then(data => {
        if (data.status === "success" && Array.isArray(data.comments_and_images)) {
          const comments = data.comments_and_images.map(comment => ({
            text: comment.text,
            authorName: comment.author_name,
            date: comment.date,
            imageUrls: comment.image_urls || [] 
          }));
          console.log(comments)
          setComments(comments); 
        } else {
          console.error('Failed to fetch comments and images or data format incorrect', data);
          setComments([]); 
        }
      })
      .catch(error => {
        console.error('Error fetching comments and images:', error);
        setComments([]); 
      });
}, [restaurant._id]); 


  useEffect(() => {
    if (activeTab === 'location') {
      const url = IN_DEV ? localhost_server : path_server;
      fetch(`${url}/api/location/${encodeURIComponent(restaurant._id.$oid)}`)
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            setLocationDetails(data.locationDetails);
          } else {
            console.error('Failed to fetch location details', data);
            setLocationDetails(null);
          }
        });
    }
  }, [activeTab, restaurant._id]);

  const openModal = (commentIndex, imageIndex) => {
    setCurrentCommentIndex(commentIndex);
    setCurrentImageIndex(imageIndex);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showPreviousImage = () => {
    const numImages = comments[currentCommentIndex].imageUrls.length;
    setCurrentImageIndex(prev => prev > 0 ? prev - 1 : numImages - 1);
  };
  
  const showNextImage = () => {
    const numImages = comments[currentCommentIndex].imageUrls.length;
    setCurrentImageIndex(prev => (prev + 1) % numImages);
  };
  
  

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this restaurant?")) {
      const url = IN_DEV ? localhost_server : path_server;
      fetch(`${url}/api/restaurant/${encodeURIComponent(restaurant._id.$oid)}`, {
        method: 'DELETE'
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            onDelete(restaurant._id.$oid);
          } else {
            console.error('Failed to delete restaurant', data);
          }
        });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No date available';
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const getGalleryItems = () => {
    const galleryItems = comments.map(comment => ({
      imageUrls: comment.imageUrls || [],  // Assuming 'imageUrls' is an array of image URLs associated with the comment
      comment: comment.text || 'No comment available',
      author: comment.authorName || 'Unknown author',
      date: comment.date ? formatDate(comment.date) : 'No date available'
    }));
    return galleryItems;
  };
  

  return (
    <div className="info-window">
      <button className="close-button" onClick={onClose}>X</button>
      <h2>{restaurant.name}</h2>
      <div className="button-group">
        <button className={activeTab === 'comments' ? 'active' : ''} onClick={() => setActiveTab('comments')}>Comments</button>
        <button className={activeTab === 'location' ? 'active' : ''} onClick={() => setActiveTab('location')}>Information</button>
        {loggedInUser && loggedInUser.role === 'admin' && (
          <button className="delete-button" onClick={handleDelete}>Delete</button>
        )}
      </div>
      <div className="content">
    {activeTab === 'comments' && (
        <div className="image-gallery">
        {getGalleryItems().map((item, index) => (
            <div key={index} className="gallery-item">
                {/* Render each image from the imageUrls array */}
                {item.imageUrls.map((url, imgIndex) => (
                    <img 
                        key={imgIndex}
                        src={url} // Corrected from url[0] to url
                        alt={`Image ${imgIndex + 1} of ${item.author}`}
                        className="gallery-image"
                        onClick={() => openModal(index, imgIndex)} // Passing index and imgIndex to handle modal logic
                    />
                ))}
                <div className="comment-section">
                    <p className="comment-text">{item.comment}</p>
                    <p className="comment-author">- {item.author} posted on {item.date}</p>
                </div>
                {index < getGalleryItems().length - 1 && <div className="separator"></div>}
            </div>
        ))}
    </div>
    
    )}
        {activeTab === 'location' && locationDetails && (
          <div>
            <h2 className="info-name">{restaurant.name}</h2>
            <p className="info-address">{locationDetails.address || 'No address provided'}</p>
            <p className="info-phone">{locationDetails.phone || 'No phone number provided'}</p>
            <p className="info-price-range">{locationDetails.price_range || 'No price range provided'}</p>
            {locationDetails.opening_hours ? (
              <>
                <p className="info-opening-hours-title">Opening Hours</p>
                <ul className="info-opening-hours">
                  {locationDetails.opening_hours.map((hour, index) => (
                    <li key={index}>{hour}</li>
                  ))}
                </ul>
              </>
            ) : (
              <p>No opening hours provided</p>
            )}
            <a href={locationDetails.googleMapsUrl} target="_blank" rel="noopener noreferrer" className="google-maps-link">
              View on Google Maps
            </a>
          </div>
        )}
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div className="modal-content">
          <button className="modal-close-button" onClick={closeModal}>X</button>
          {comments.length > currentCommentIndex && comments[currentCommentIndex] && comments[currentCommentIndex].imageUrls && (
            <>
              <div className="image-container">
                <button className="modal-nav-button prev-button" onClick={showPreviousImage}>{"<"}</button>
                <div className="image-wrapper">
                  <img src={comments[currentCommentIndex].imageUrls[currentImageIndex]} alt="Restaurant" className="modal-image" />
                  <div className="comment-section">
                    <p className="comment-text">{comments[currentCommentIndex].text}</p>
                    <p className="comment-author">
                      - {comments[currentCommentIndex].authorName} posted on {comments[currentCommentIndex].date ? formatDate(comments[currentCommentIndex].date) : 'No date available'}
                    </p>
                  </div>
                </div>
                <button className="modal-nav-button next-button" onClick={showNextImage}>{">"}</button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RestaurantInfoWindow;