import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Grid, Box } from '@mui/material';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Switch from 'react-switch'; 
import { mapContainerStyle, mapStyles, messageStyle } from './styles';
import InfoWindowContent from './InfoWindowContent';
import { fetchRestaurants } from './api';
import RestaurantInfoWindow from './RestaurantInfoWindow';
import SearchBarAll from './SearchBarAll'; 
import RatingFilter from './RatingFilter';
import AuthApp from './AuthApp';
import { UserContext } from './UserContext';
import ContactForm from './ContactForm'; 
import Logo from '../assets/logo.jpg'; 
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { IN_DEV, localhost_server, path_server } from './api.js';

const center = {
  lat: 59.911491,
  lng: 10.757933
};

const mainMapId = "32f8a5e3c0c9082";	
const GoogleMap_API_KEY = "AIzaSyD_hm9hi2lIKp1dDh32I69oDu6OYgUBaJ0";

const MapComponent = () => {
  const { loggedInUser } = useContext(UserContext);
  const [placeDetails, setPlaceDetails] = useState(null);
  const [userLocation, setUserLocation] = useState(center);
  const [map, setMap] = useState(null);
  const [showMainMap, setShowMainMap] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [selectedPOI, setSelectedPOI] = useState(null);
  const [infoText, setInfoText] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);  
  const [file, setFile] = useState(null);
  const [showAuth, setShowAuth] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [leftPaneWidth, setLeftPaneWidth] = useState(window.innerWidth * 0.35);  
  const [zoom, setZoom] = useState(15); 
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [contactOpen, setContactOpen] = useState(false); 
  const [temporaryMarker, setTemporaryMarker] = useState(null); 
  const [user, setUser] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position)
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          console.error("Error while getting the localisation of the user.");
        }
      );
    }
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); 
  };

  const handleSwitchChange = (checked) => {
    setShowMainMap(!checked);
  };

  const handleSaveInfo = (infoText) => {
    const userId = loggedInUser ? loggedInUser.id : null;
    const formData = new FormData();
    formData.append('name', selectedPOI.name);
    formData.append('location', JSON.stringify({ lat: selectedPOI.lat, lng: selectedPOI.lng, full_address: selectedPOI.address}));
    formData.append('features', JSON.stringify({ playArea: true, highChairs: true, kidsGames: true }));
    formData.append('reviewText', infoText);
    formData.append('userId', userId);

    console.log(uploadedFiles)
    uploadedFiles.forEach(file => {
        formData.append('image', file);
    });

    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/check-and-upload-restaurant`, {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        console.log('Operation successful with Restaurant ID:', data.restaurant_id);
        setMessage('Information saved successfully!');
        setIsError(false);
      } else {
        throw new Error(data.message);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setMessage('Error saving information. Please try again.');
      setIsError(true);
    })
    .finally(() => {
      setInfoText('');
      setSelectedPOI(null);
    });
};
  const handleCloseWindow = () => {
    setInfoText('');
    setSelectedPOI(null);
    setTemporaryMarker(null); 
  }

  const handleCloseWindowSelectedResto = () => {
    setSelectedRestaurant(null);
  }

  const handleResize = (event, { size }) => {
    setLeftPaneWidth(size.width);
  };

  const handleAuthButtonClick = () => {
    setShowAuth((prevShowAuth) => !prevShowAuth);
  };
  
  const handleContactClick = () => {
    setContactOpen(true);
  };

  const handleContactClose = () => {
    setContactOpen(false);
  };

  const onMapLoad = useCallback((map) => {
    setMap(map);
    setGoogleMapsLoaded(true);
    map.addListener('zoom_changed', () => {
      setZoom(map.getZoom());
    });
    map.addListener('click', (event) => {
      if (event.placeId) {
        event.stop();
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({
          placeId: event.placeId,
          fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours']
        }, (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSelectedPOI({
              name: result.name,
              address: result.formatted_address,
              place_id: result.place_id,
              phone: result.formatted_phone_number,
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
              photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
              googleMapsUrl: result.url,
              price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
              opening_hours: result.opening_hours ? result.opening_hours.weekday_text : []
            });
            setMessage('');
            setShowMessage(false);
            setTemporaryMarker(null); // Remove temporary marker when a new place is clicked
          }
        });
      }
    });
  }, []);

  const handlePlaceSelected = (place) => {
    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails({
      placeId: place.place_id,
      fields: ['name', 'formatted_address', 'place_id', 'formatted_phone_number', 'geometry', 'photos', 'url', 'price_level', 'opening_hours']
    }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSelectedPOI({
          name: result.name,
          address: result.formatted_address,
          place_id: result.place_id,
          phone: result.formatted_phone_number,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
          photos: result.photos ? result.photos.map(photo => photo.getUrl({ maxWidth: 500, maxHeight: 500 })) : [],
          googleMapsUrl: result.url,
          price_range: result.price_level ? '$'.repeat(result.price_level) : 'No price range provided',
          opening_hours: result.opening_hours ? result.opening_hours.weekday_text : []
        });
        map.panTo(result.geometry.location);
        map.setZoom(15);
        setMessage('');
        setShowMessage(false);
      }
    });
  };

  const handleDeleteRestaurant = (restaurantId) => {
    const url = IN_DEV ? localhost_server : path_server;
    fetch(`${url}/api/restaurant/${encodeURIComponent(restaurantId)}`, {
      method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        setRestaurants((prevRestaurants) =>
          prevRestaurants.filter((restaurant) => restaurant._id.$oid !== restaurantId)
        );
        setSelectedRestaurant(null);
      } else {
        console.error('Failed to delete restaurant', data);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    const handleResizeWindow = () => {
      setLeftPaneWidth(window.innerWidth * 0.35);
    };

    window.addEventListener('resize', handleResizeWindow);
    return () => window.removeEventListener('resize', handleResizeWindow);
  }, []);

  
  useEffect(() => {
    let timer;
    if (message) {
      setShowMessage(true);
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 2000); 
    }
    return () => clearTimeout(timer); 
  }, [message]);

  useEffect(() => {
    if (showMainMap) {
      fetchRestaurants().then(setRestaurants).catch(error => {
        console.error('Error:', error);
        setRestaurants([]);
      });
    }
  }, [showMainMap]);

  

  useEffect(() => {
    async function fetchUserData() {
      try {
        const url = IN_DEV ? localhost_server : path_server;
        const response = await fetch(`${url}/api/check-login-status`, { credentials: 'include' });
        const data = await response.json();
        if (data.loggedIn) {
          setUser(data.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchUserData();
  }, []);

  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
          <img src={Logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Dining With Kids
          </Typography>
          {loggedInUser ? (
            <>
              <Typography variant="subtitle1" style={{ paddingRight: '10px', marginLeft: 'auto', color: '#FFFFFF' }}>
                Show editing map:
              </Typography>
              <Switch
                onChange={handleSwitchChange}
                checked={!showMainMap}
                id="data-toggle"
              />
              <Typography variant="subtitle1" style={{ paddingLeft: '10px', marginLeft: 'auto', color: '#FFFFFF', fontWeight: 'bold' }}>
                Welcome, {loggedInUser.firstName} {loggedInUser.lastName}!
              </Typography>
            </>
          ) : (
            <Button color="inherit" onClick={handleAuthButtonClick}>
              Sign In
            </Button>
          )}
          <Button color="inherit" onClick={handleContactClick} style={{ fontSize: '0.875rem' }}>
            Contact Us
          </Button>
          <Button color="inherit" href="https://www.termsfeed.com/live/06452dfa-dbac-4c47-a190-f713b8708bfa" target="_blank" style={{ fontSize: '0.875rem' }}>
            Privacy Policy
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container>
        <ResizableBox
          width={leftPaneWidth}
          height={window.innerHeight - 64}
          axis="x"
          minConstraints={[window.innerWidth * 0.2, window.innerHeight - 64]}
          maxConstraints={[window.innerWidth * 0.4, window.innerHeight - 64]}
          resizeHandles={['e']}
          onResize={handleResize}
        >
          <Grid item xs={12} style={{ padding: '1rem', overflowY: 'auto', height: '100%' }}>
            <Box display="flex" alignItems="center" style={{ marginBottom: '1rem' }}>
              {map && <SearchBarAll map={map} onPlaceSelected={handlePlaceSelected} setTemporaryMarker={setTemporaryMarker} style={{ flex: 1 }} />}
              <RatingFilter setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
            </Box>
            {selectedPOI && loggedInUser && loggedInUser.role === 'admin' && (
              <InfoWindowContent
                restaurant={selectedPOI}
                onFileChange={handleFileChange}
                onSave={handleSaveInfo}
                onClose={handleCloseWindow}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
            )}
            {selectedRestaurant && (
                <RestaurantInfoWindow
                  restaurant={selectedRestaurant}
                  onClose={handleCloseWindowSelectedResto}
                  onDelete={handleDeleteRestaurant} 
                />
            )}
          </Grid>
        </ResizableBox>
        <Grid item xs style={{ width: `calc(100% - ${leftPaneWidth}px)`, height: 'calc(100vh - 64px)' }}>
          {showMessage && <div style={{ ...messageStyle, color: isError ? 'red' : 'green' }}>{message}</div>}
          <LoadScript googleMapsApiKey={GoogleMap_API_KEY} libraries={['places']}>
            <GoogleMap
              key={showMainMap ? 'custom-map' : 'default-map'}
              mapContainerStyle={{ height: '100%', width: '100%' }}
              center={userLocation}
              zoom={zoom}
              onLoad={map => onMapLoad(map)}
              options={{
                mapId: showMainMap ? mainMapId : null,
              }}
            >
              {googleMapsLoaded && showMainMap ? (
                restaurants.map(restaurant => (
                  <Marker
                    key={restaurant._id || restaurant.place_id}  
                    position={{ lat: restaurant.location.lat, lng: restaurant.location.lng }}
                    label={zoom >= 14 ? {
                      text: restaurant.name,
                      color: "darkgreen",
                      fontSize: "9px",
                      fontWeight: "bold",
                      background: "red",
                    } : null}
                    onClick={() => setSelectedRestaurant(restaurant)}
                  />
                ))
              ) : (
                // Add your default markers here, or null if no defaults are wanted
                null
              )}
              {temporaryMarker && (
                <Marker
                  position={temporaryMarker}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                  }}
                />
              )}
            </GoogleMap>
          </LoadScript>
        </Grid>
      </Grid>
      {showAuth && <AuthApp handleClose={handleAuthButtonClick}/>}
      <ContactForm open={contactOpen} handleClose={handleContactClose} />
    </div>
  );
};

export default MapComponent;