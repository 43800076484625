import React, { useState, useRef, useEffect } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CheckIcon from '@mui/icons-material/Check';

const RatingFilter = ({ anchorEl, setAnchorEl }) => {
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedDistance, setSelectedDistance] = useState('');
  const [selectedRatings, setSelectedRatings] = useState([]);

  const menuRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value, type) => {
    if (type === 'price') {
      setSelectedPrices((prevSelectedPrices) =>
        prevSelectedPrices.includes(value)
          ? prevSelectedPrices.filter((price) => price !== value)
          : [...prevSelectedPrices, value]
      );
    } else if (type === 'distance') {
      setSelectedDistance((prevSelectedDistance) =>
        prevSelectedDistance === value ? '' : value
      );
    } else if (type === 'rating') {
      setSelectedRatings((prevSelectedRatings) =>
        prevSelectedRatings.includes(value)
          ? prevSelectedRatings.filter((rating) => rating !== value)
          : [...prevSelectedRatings, value]
      );
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log('Selected Prices:', selectedPrices);
  }, [selectedPrices]);

  useEffect(() => {
    console.log('Selected Distance:', selectedDistance);
  }, [selectedDistance]);

  useEffect(() => {
    console.log('Selected Ratings:', selectedRatings);
  }, [selectedRatings]);

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
        size="large"
        style={{ marginLeft: '1rem' }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        ref={menuRef}
      >
        <Box display="flex" flexDirection="column">
          <MenuItem disabled>Prix</MenuItem>
          {['$', '$$', '$$$', '$$$$'].map((price, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(price, 'price')}>
              <ListItemIcon>
                {selectedPrices.includes(price) && <CheckIcon />}
              </ListItemIcon>
              <ListItemText primary={price} />
            </MenuItem>
          ))}
          <MenuItem disabled>Distance</MenuItem>
          {['Within 1 km', 'Within 5 km', 'Within 10 km', 'Within 20 km'].map((distance, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(distance, 'distance')}>
              <ListItemIcon>
                {selectedDistance === distance && <CheckIcon />}
              </ListItemIcon>
              <ListItemText primary={distance} />
            </MenuItem>
          ))}
          <MenuItem disabled>Ratings</MenuItem>
          {['1 Star', '2 Stars', '3 Stars', '4 Stars', '5 Stars'].map((rating, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(rating, 'rating')}>
              <ListItemIcon>
                {selectedRatings.includes(rating) && <CheckIcon />}
              </ListItemIcon>
              <ListItemText primary={rating} />
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  );
};

export default RatingFilter;
