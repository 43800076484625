// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './components/UserContext';
import MapComponent from './components/MapComponent';

function App() {
  return (
    <Router>
      <UserProvider>
        <div className="App">
        <MapComponent />
      </div>
      </UserProvider>
    </Router>
    
  );
}

export default App;

