import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const IN_DEV = false;
export const localhost_server = "http://127.0.0.1:5000";
export const path_server = "https://cadeaunoelfamille.com";
export const fetchRestaurants = async () => {
  try {
    const response = await fetch(IN_DEV ? `${localhost_server}/api/get-restaurant-info` : `${path_server}/api/get-restaurant-info`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data.status === "success") {
      return JSON.parse(data.data);
    } else {
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    console.error("Error fetching restaurant data:", error);
    throw error;
  }
};