import React, { useState } from 'react';
import '../styles/InfoWindowContent.css'; 

const InfoWindowContent = ({ restaurant, onFileChange, onSave, onClose, uploadedFiles, setUploadedFiles }) => {
  const [infoText, setInfoText] = useState('');
  const [photoIndex, setPhotoIndex] = useState(0);
  

  const nextPhoto = () => {
    setPhotoIndex((photoIndex + 1) % restaurant.photos.length);
  };

  const previousPhoto = () => {
    setPhotoIndex(photoIndex === 0 ? restaurant.photos.length - 1 : photoIndex - 1);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles(files);
    onFileChange(event);
  };

  return (
    <>
      <button className="close-button" onClick={onClose}>X</button>
      <h2 className="info-name">{restaurant.name}</h2>
      {restaurant.photos && restaurant.photos.length > 0 && (
        <div className="photo-gallery">
          <button onClick={previousPhoto} className="gallery-button gallery-button-left">{"<"}</button>
          <img 
            src={restaurant.photos[photoIndex]} 
            alt={restaurant.name} 
            className="info-photo" 
          />
          <button onClick={nextPhoto} className="gallery-button gallery-button-right">{">"}</button>
        </div>
      )}
      <p className="info-address">{restaurant.address || 'No address provided'}</p>
      <p className="info-phone">{restaurant.phone || 'No phone number provided'}</p>
      <p className="info-price-range">{restaurant.price_range || 'No price range provided'}</p>
      {restaurant.opening_hours ? (
        <>
          <p className="info-opening-hours-title">Opening Hours</p>
          <ul className="info-opening-hours">
            {restaurant.opening_hours.map((hour, index) => (
              <li key={index}>{hour}</li>
            ))}
          </ul>
        </>
      ) : (
        <p>No opening hours provided</p>
      )}
      <a href={restaurant.googleMapsUrl} target="_blank" rel="noopener noreferrer" className="google-maps-link">
        View on Google Maps
      </a>
      <textarea
        value={infoText}
        onChange={e => setInfoText(e.target.value)}
        placeholder="Enter additional info"
        className="info-textarea"
      />
      <div className="info-file-upload">
        <label className="info-file-label" htmlFor="file-input">
          Upload Image
          <input type="file" onChange={handleFileChange} className="info-file-input" multiple id="file-input" />
        </label>
        {uploadedFiles.length > 0 && (
          <p className="upload-feedback">Uploaded {uploadedFiles.length} file(s)</p>
        )}
      </div>
      <button onClick={() => onSave(infoText)} className="info-save-button">
        Save Info
      </button>
    </>
  );
};

export default InfoWindowContent;
