export const mapContainerStyle = {
  height: 'calc(100vh - 64px)', 
  width: '75vw',
};

export const messageStyle = {
  animation: 'fadeInOut 2s ease-in-out', 
  opacity: 1,  
  color: '#333',  
  textAlign: 'center',
  fontSize: '14px',  
  padding: '8px 20px',  
  position: 'absolute',
  top: '10%',  
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'auto',  
  backgroundColor: '#f8f8f8',  
  boxShadow: '0 2px 4px rgba(0,0,0,0.06)',  
  borderRadius: '4px',  
  zIndex: 1000
};

export const buttonSignInStyle = {
  position: 'absolute',  
  top: '95%',            
  left: '50%',           
  transform: 'translate(-50%, -50%)',  
  width: '150px',        
  padding: '10px',
  backgroundColor: '#007BFF',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  zIndex: 1000,
  textAlign: 'center',   
  transition: 'background-color 0.3s ease' 
};

export const buttonHoverStyle = {
  backgroundColor: '#0056b3' 
};

export const mapStyles = [
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'poi.park',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'poi.attraction',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'poi.place_of_worship',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.school',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.sports_complex',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.medical',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'administrative',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape',
    stylers: [{ visibility: 'off' }],
  },
];
