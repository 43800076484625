import { useNavigate } from 'react-router-dom';

export const useAuth = () => {
  const navigate = useNavigate();

  const loginWithGoogle = () => {
    const newWindow = window.open('http://127.0.0.1:5000/api/login-google', '_blank', 'width=500,height=600');

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        checkLoginStatus();
      }
    }, 500);
  };

  const loginWithFacebook = () => {
    const newWindow = window.open('http://127.0.0.1:5000/api/login-facebook', '_blank', 'width=500,height=600');

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        checkLoginStatus();
      }
    }, 500);
  };

  const clearcookies = () => {
    fetch('http://127.0.0.1:5000/api/logout')
      .then(response => response.json())
      .then(data => {
        if (data.loggedIn) {
            console.log("Logged out")  
        } else {
            console.log("not logged out")  
        }
      });
  };

  const checkLoginStatus = () => {
    fetch('http://127.0.0.1:5000/api/check-login-status', {
      method: 'GET', // Ajout explicite de la méthode GET
      credentials: 'include' // S'assurer que les cookies sont envoyés avec la requête
    })
    .then(response => response.json())
    .then(data => {
      if (data.loggedIn) {
        navigate('/');  // Assurez-vous que le chemin est correct
      } else {
        alert('Login failed, please try again');
      }
    })
    .catch(error => {
      console.error('Error checking login status:', error);
      alert('Error checking login status. Please check console for details.');
    });
  };
  

  return { loginWithGoogle, loginWithFacebook };
};
